import React from "react"

import { DefaultLayout } from "../layouts/Default"
import { SEO } from "../components/SEO"

const StaticPage = () => {
  return (
    <DefaultLayout>
      <SEO
        title="404: This Page could not be found"
        description="This page doesn't exist."
      />

      <div className="py-20">
        <div className="container mx-auto px-8 max-w-screen-md flex justify-center items-center">
          <h1 className="text-2xl font-semibold leading-tight mt-1 mb-2 text-gray-800">
            404 | This page could not be found
          </h1>
        </div>
      </div>
    </DefaultLayout>
  )
}

export default StaticPage
